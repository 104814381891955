<template>
  <div>
    <div class="mb-8">
      <page-header :heading="pageHeading" :sub-heading="pageSubHeading"></page-header>
    </div>
    <div
      class="my-8 d-flex justify-end"
    >
      <v-btn
        @click="handleBack"
        color="green"
        class="white--text"
        v-text="backButtonText"
      ></v-btn>
    </div>
    <create-contract-summary-form
      standalone
      :template-uuid="templateUuid"
      @saved="handleSaved"
    ></create-contract-summary-form>
    <confirmation-dialog
      ref="leaveRouteConfirmationDialog"
      xs-column
      v-model="leaveRouteConfirmationDialog"
      :title="leaveRouteConfirmationDialogTitle"
      :text="leaveRouteConfirmationDialogText"
      :cancel="leaveRouteConfirmationDialogCancelText"
      :confirm="leaveRouteConfirmationDialogConfirmText"
      confirm-color="red"
      @cancel="cancelLeaveRoute"
      @confirm="confirmLeaveRoute"
    ></confirmation-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import PageHeader from '@/components/PageHeader'
import CreateContractSummaryForm from '@/components/contract-summaries/CreateContractSummaryForm'

import i18n from '@/mixins/page.mixin'
import Page from '@/mixins/i18n.mixin'
import LeaveRouteAware from '@/mixins/leaveRouteAware.mixin'

import ConfirmationDialog from '@/components/ConfirmationDialog'

export default {
  name: 'CreateTemplate',
  mixins: [
    i18n,
    Page,
    LeaveRouteAware
  ],
  components: {
    CreateContractSummaryForm,
    PageHeader,
    ConfirmationDialog
  },
  props: {
    templateUuid: {
      type: String,
      default: null
    }
  },
  data () {
    return {}
  },
  destroyed () {
    this.setContractSummaryTemplate(null)
  },
  methods: {
    ...mapActions('contractSummaries', ['setContractSummaryTemplate']),
    handleBack () {
      this.$router.push({ name: 'templates' })
    },
    handleSaved () {
      this.watchRouteChange = false
      this.$router.push({ name: 'templates', query: { saved: true } })
    }
  },
  computed: {
    backButtonText () {
      return this.$tp('components.buttons.backButton.text')
    }
  }
}
</script>
